import { takeLatest, put, call, delay } from "redux-saga/effects"; // Import necessary functions from Redux Saga
import { FAVOURITE } from "../../../utility/constants"; // Import constant for API endpoint

import {
  DELETE_MYLIST_REQUEST, // Import action type for deleting items from favorites list
  deleteMylistSuccess, // Import action creator for successful deletion
  deleteMylistFailure, // Import action creator for deletion failure
} from "../../actions/mylistActions/deletemylistAction"; // Import action creators for success and failure cases

// Saga function to handle deletion of items from favorites list
function* fetchMyListDelete(action) {
  try {
    // Make DELETE request to the FAVOURITE endpoint with payload
    const response = yield call(fetch, FAVOURITE, {
      method: "DELETE", // Use DELETE method for deletion
      headers: {
        token: localStorage.getItem("token"), // Include token from local storage in the request headers
      },
      body: action.payload, // Include payload (item to be deleted) in the request body
    });

    const data = yield response.json(); // Parse response data

    // Check if the deletion was successful
    if (data.status === "Success") {
      // Dispatch action to handle successful deletion
      yield put(deleteMylistSuccess(data));
      yield delay(3000); // Delay for 3 seconds
      window.location.reload(); // Reload the page after 5 seconds
      // Additional actions or logic here if needed
    } else {
      // Dispatch action to handle deletion failure
      yield put(deleteMylistFailure(data.error));
    }
  } catch (error) {
    // Dispatch action to handle deletion failure with error message
    yield put(deleteMylistFailure(error.message));
  }
}

// Watcher saga to trigger the deletion of items from favorites list
export function* deleteMylistSaga() {
  yield takeLatest(DELETE_MYLIST_REQUEST, fetchMyListDelete);
}

// Export the watcher saga function as default
export default deleteMylistSaga;
