
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { ModalProvider, ModalContext } from "../modal/modalContext"; // Importing ModalProvider and ModalContext
import toast, { Toaster } from 'react-hot-toast'; // Import the toast function from react-hot-toast
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { MyContext } from '../../PageRouters/layouts/publicLayout';
import { useLocation } from "react-router-dom";

const AudioPlayerComponent = (props) => {
  // console.log("audioprops", props)
  const playerRef = useRef(null);
  const [state, setState] = useState({
    audioFile: props.data,
    audioIndex: 0,
    loop: false
  });
  
  const [previousUrl, setPreviousUrl] = useState(window.location.href);
  const { openModal, setCustomModal } = useContext(ModalContext); // Using useContext hook to get openModal and setCustomModal functions from ModalContext
  const [open, setOpen] = React.useState(false); // State variable for modal open status
  const history = useHistory();
  const isAuth = localStorage.getItem("isConnected") === "true";

  // Get current location using useLocation hook from react-router-dom
  const location = useLocation();

  const {
    loop
  } = state;

  const context = useContext(MyContext);
  // console.log("context", context.radioView)
  //minimize, full

  useEffect(() => {
    // 5 mins login popup logic starts here
    const handleUrlChange = () => {
      // alert("naviate")
      const currentUrl = window.location.href;

      if (currentUrl !== previousUrl) {
        // sessionStorage.setItem("totalPlayedDuration", totalPlayedDurationSumArr.push(playerRef.current.getCurrentTime()))
        // URL has changed
        setPreviousUrl(currentUrl);
      }
    };
    window.navigation.addEventListener('navigate', handleUrlChange);

    let audioUrlTrack = location.pathname.indexOf("audio")
    // console.log("audioUrlTrack", audioUrlTrack)
    if (!isAuth && (audioUrlTrack === 7 && context.radioView === "full")) {
      let totalPlayedDurationSumArr = sessionStorage.getItem("totalPlayedDuration") ? sessionStorage.getItem("totalPlayedDuration") : 0;
      let currentPlayedDuration = sessionStorage.getItem("currentPlayedDuration") ? sessionStorage.getItem("currentPlayedDuration") : 0;
      // alert(JSON.stringify(totalPlayedDurationSumArr))
      if (!totalPlayedDurationSumArr) {
        sessionStorage.setItem("totalPlayedDuration", 0);
      } else {
        let playerCurrentTime = parseInt(currentPlayedDuration)
        // console.log("totalplayedduration", playerCurrentTime)
        totalPlayedDurationSumArr = totalPlayedDurationSumArr + ',' + playerCurrentTime;
        // totalPlayedDurationSumArr = sessionStorage.getItem("totalPlayedDuration") + ',' + currentPlayedDuration;
        sessionStorage.setItem("totalPlayedDuration", totalPlayedDurationSumArr.replace(/,0/g, ''));
      }
    }
    return () => {
      window.navigation.removeEventListener('navigate', handleUrlChange);
    }
  
    // 5 mins login popup logic end here
  }, [previousUrl])

  const onChangeNext = () => {
    if (context?.audioFiles[context?.playSongIndex]?.song ? context?.audioFiles[context?.playSongIndex]?.song : '' === '') {
      setState({ ...state, loop: true })
      return
    }

    if (context?.playSongIndex !== context?.playSongIndex.length - 1) {
      setState((prevState) => ({
        ...prevState,
        audioIndex: state.audioIndex + 1,
      }));
      let value = context?.playSongIndex + 1
      context.setplaySongIndex(value)

    }
    if (context?.playSongIndex === context?.playSongIndex.length - 1) {
      setState((prevState) => ({
        ...prevState,
        audioIndex: 0
      }));
      let value = context?.playSongIndex - 1
      context.setplaySongIndex(value)
    }

  }

  const onChangePrev = () => {
    if (context?.playSongIndex === 0) {
      setState((prevState) => ({
        ...prevState,
        audioIndex: 0
      }));
      context.setplaySongIndex(0)
    } else {
      setState((prevState) => ({
        ...prevState,
        audioIndex: state.audioIndex - 1
      }));
      let value = context?.playSongIndex - 1
      context.setplaySongIndex(value)
    }

  }

  const handleListen = (e) => {
    // console.log('duration:', e.target.duration);
    // console.log('currentTime:', e.target.currentTime);
    if (!isAuth) {
      let getPlayerCurrentTime = parseInt(e.target.currentTime)
      let totalPlayedDuration = sessionStorage.getItem("totalPlayedDuration").split(",")
      let totalPlayedDurationLimit = sessionStorage.getItem("totalPlayedDurationLimit") * 60 || 0
      let totalPlayedDurationLimitMessage = sessionStorage.getItem("totalPlayedDurationLimitMessage") || ""
      let filteredArray = totalPlayedDuration.filter(value => !isNaN(value));
      // console.log(filteredArray);
      let sum = filteredArray.reduce((accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue), 0);
      // console.log("totalPlayedDuration", sum)
      let sumofSessionStoragePlayerTime = parseInt(sum) || 0

      //navigation case starts here
      if (getPlayerCurrentTime > 3) {
        sessionStorage.setItem("currentPlayedDuration", parseInt(playerRef.current.audio.current.currentTime));
      }
      //navigation case starts end here

     // -60(-1 value fetch from common data api) seconds for complete audio playback without logging
      if ((totalPlayedDuration.length > 0 && totalPlayedDurationLimit !== -60) && ((getPlayerCurrentTime > totalPlayedDurationLimit) || (sumofSessionStoragePlayerTime > totalPlayedDurationLimit))) {

        context.setisOpenRadio(false);
        document.body.classList.remove('radioOpen')
        context.setplaySongIndex(null)
        context.setAudeioFiles([{}])

        setCustomModal("login"); // Set the custom value
        setOpen(true)
        history.push({
          pathname: "/"
        });

        toast.success(totalPlayedDurationLimitMessage, {
          duration: 10000,
          // iconTheme: {
          //   primary: "#000",
          //   secondary: "blue"
          // },
          // role: "status",
          // ariaLive: "polite",
          // style: {
          //   background: "green",
          //   color: "whitesmoke",
          // },
        });
        openModal();
      }
    }
  }

  // useEffect(() => {


  // }, [context.playSongIndex])

  // console.log("abhi", context?.audioFiles[context?.playSongIndex]?.song ? context?.audioFiles[context?.playSongIndex]?.song : '')

  return (
    <AudioPlayer
      autoPlay
      ref={playerRef}
      showSkipControls={true}
      autoPlayAfterSrcChange={true}
      src={context?.audioFiles[context?.playSongIndex]?.song ? context?.audioFiles[context?.playSongIndex]?.song : ''}
      showJumpControls={false}
      loop={loop}
      //muted={state.isMutedRadioPlayer}
      // onPlay={e => console.log("onPlay")}
      onClickPrevious={(e) => {
        onChangePrev();
        props.prevSong(context?.playSongIndex ? context?.playSongIndex : 0);
      }}
      onClickNext={(e) => {
        onChangeNext();
        props.nextSong(context?.playSongIndex ? context?.playSongIndex : 0);
      }}
      onListen={handleListen}
      onEnded={() => {
        onChangeNext();
        props.nextSong(context?.playSongIndex ? context?.playSongIndex : 0);
      }}
    // other props here
    />
  )
}

export default AudioPlayerComponent;